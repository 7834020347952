import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import {
  faBadgeCheck,
  faBarsFilter,
  faGaugeSimple,
  faLaptopMobile,
  faShieldHalved,
  faUserGroup,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { Skeleton, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import SCPartnerDashboard from './PartnerDashboard.style';
import { SUBSCRIPTIONS_PRODUCTS } from '../../hooks/subscriptionType/useSubscriptionType';
import {
  getBarChartOptions,
  getDonutChartOptions,
} from '../../utils/chartFactory/chartFactory';
import { useTranslation } from '../../i18n';
import SectionCard from '../../components/molecules/SectionCard/SectionCard';
import usePartnerClients from '../../hooks/usePartnerClients/usePartnerClients';
import {
  PARTNER_CLIENT_SUBSCRIPTIONS_STATUS,
  getSubscriptionStatus,
} from '../PartnerClients/PartnerClients';
import { getDateFormat } from '../../utils/format/dataFormat';
import KPICard from '../../components/molecules/KPICard/KPICard';

const ChartSkeleton = () => (
  <Skeleton
    active
    title={false}
    paragraph={{ rows: 3, width: ['30%', '100%', '50%'] }}
  />
);

const WIDGET = {
  SUBSCRIPTION_STATE: 'SUBSCRIPTION_STATE',
  LICENSES_RANGE: 'LICENSES_RANGE',
  PROTECTION_LEVEL: 'PROTECTION_LEVEL',
  LICENSES: 'LICENSES',
};

const OPERATOR_FILTER = {
  LICENSES_RANGE_LESS_THAN_10: {
    key: 'LICENSES_RANGE_LESS_THAN_10',
    callback: ({ status }) => status.nbLicenses < 10,
  },
  LICENSES_RANGE_BETWEEN_10_AND_50: {
    key: 'LICENSES_RANGE_BETWEEN_10_AND_50',
    callback: ({ status }) =>
      status.nbLicenses >= 10 && status.nbLicenses <= 50,
  },
  LICENSES_RANGE_MORE_THAN_50: {
    key: 'LICENSES_RANGE_MORE_THAN_50',
    callback: ({ status }) => status.nbLicenses > 50,
  },
  SUBSCRIPTION_STATE_EXPIRED: {
    key: 'SUBSCRIPTION_STATE_EXPIRED',
    callback: ({ status }) =>
      status.active < new Date() ||
      status.serviceLevelIDs[0] === SUBSCRIPTIONS_PRODUCTS.DEFAULT,
  },
  SUBSCRIPTION_STATE_LESS_THAN_3_MONTHS: {
    key: 'SUBSCRIPTION_STATE_LESS_THAN_3_MONTHS',
    callback: ({ status }) => {
      const renewalDate = new Date(status.nextRenewalAt);
      return (
        status.serviceLevelIDs[0] === SUBSCRIPTIONS_PRODUCTS.PAYING &&
        renewalDate <= threeMonthsAfterNow
      );
    },
  },
  SUBSCRIPTION_STATE_MORE_THAN_3_MONTHS: {
    key: 'SUBSCRIPTION_STATE_MORE_THAN_3_MONTHS',
    callback: ({ status }) => {
      const renewalDate = new Date(status.nextRenewalAt);

      return (
        status.serviceLevelIDs[0] === SUBSCRIPTIONS_PRODUCTS.PAYING &&
        renewalDate > threeMonthsAfterNow
      );
    },
  },
  PROTECTION_LEVEL_UNKNOWN: {
    key: 'PROTECTION_LEVEL_UNKNOWN',
    callback: ({ scoring }) => {
      const score = scoring.lastValue;
      return score === undefined || score === null || score > 10 || score < 0;
    },
  },
  PROTECTION_LEVEL_CRITICAL: {
    key: 'PROTECTION_LEVEL_CRITICAL',
    callback: ({ scoring }) => {
      const score = scoring.lastValue;
      return score !== null && score >= 0 && score < 2.5;
    },
  },
  PROTECTION_LEVEL_LOW: {
    key: 'PROTECTION_LEVEL_LOW',
    callback: ({ scoring }) => {
      const score = scoring.lastValue;
      return score >= 2.5 && score < 5;
    },
  },
  PROTECTION_LEVEL_MEDIUM: {
    key: 'PROTECTION_LEVEL_MEDIUM',
    callback: ({ scoring }) => {
      const score = scoring.lastValue;
      return score >= 5 && score < 7.5;
    },
  },
  PROTECTION_LEVEL_HIGH: {
    key: 'PROTECTION_LEVEL_HIGH',
    callback: ({ scoring }) => {
      const score = scoring.lastValue;
      return score >= 7.5 && score <= 10;
    },
  },
  LICENSES_INSTALLED: {
    key: 'LICENSES_INSTALLED',
    callback: ({ status }) => status.nbLicensesUsed > 0,
  },
  LICENSES_SENT_NOT_INSTALLED: {
    key: 'LICENSES_SENT_NOT_INSTALLED',
    callback: ({ status }) => status.nbLicensesSent - status.nbLicensesUsed > 0,
  },
  LICENSES_AVAILABLE_TO_SEND: {
    key: 'LICENSES_AVAILABLE_TO_SEND',
    callback: ({ status }) => status.nbLicenses - status.nbLicensesSent > 0,
  },
};

const donutChartResponsiveOptions = [
  {
    breakpoint: 768,
    options: {
      legend: {
        width: 125,
      },
    },
  },
];

const averageScores = (lastAvg, n, currentValue) => {
  return (currentValue + n * lastAvg) / (n + 1);
};

const threeMonthsAfterNow = new Date().setMonth(new Date().getMonth() + 3);

const getClientsData = (clients) =>
  clients.reduce(
    (sum, { status, scoring }) => {
      const currentServiceLevel = status.serviceLevelIDs[0];
      const score = scoring.lastValue;
      const subscriptionRenewalDate = new Date(status.nextRenewalAt);

      return {
        nbLicenses: {
          total: sum.nbLicenses.total + status.nbLicenses,
          sent: sum.nbLicenses.sent + status.nbLicensesSent,
          used: sum.nbLicenses.used + status.nbLicensesUsed,
        },
        purchasedLicenses: {
          lessThan10:
            status.nbLicenses < 10
              ? sum.purchasedLicenses.lessThan10 + 1
              : sum.purchasedLicenses.lessThan10,
          between10And50:
            status.nbLicenses >= 10 && status.nbLicenses <= 50
              ? sum.purchasedLicenses.between10And50 + 1
              : sum.purchasedLicenses.between10And50,
          moreThan50:
            status.nbLicenses > 50
              ? sum.purchasedLicenses.moreThan50 + 1
              : sum.purchasedLicenses.moreThan50,
        },
        subscriptionStatus: {
          expired:
            status.active < new Date() ||
            currentServiceLevel === SUBSCRIPTIONS_PRODUCTS.DEFAULT
              ? sum.subscriptionStatus.expired + 1
              : sum.subscriptionStatus.expired,
          lessThan3Months:
            currentServiceLevel === SUBSCRIPTIONS_PRODUCTS.PAYING &&
            subscriptionRenewalDate <= threeMonthsAfterNow
              ? sum.subscriptionStatus.lessThan3Months + 1
              : sum.subscriptionStatus.lessThan3Months,
          moreThan3Months:
            currentServiceLevel === SUBSCRIPTIONS_PRODUCTS.PAYING &&
            subscriptionRenewalDate > threeMonthsAfterNow
              ? sum.subscriptionStatus.moreThan3Months + 1
              : sum.subscriptionStatus.moreThan3Months,
        },
        avgScore: {
          avg: score
            ? averageScores(sum.avgScore.avg, sum.avgScore.n, score)
            : sum.avgScore.avg,
          n: score ? sum.avgScore.n + 1 : sum.avgScore.n,
        },
        protectionLevel: {
          unknown:
            score === undefined || score === null || score > 10 || score < 0
              ? sum.protectionLevel.unknown + 1
              : sum.protectionLevel.unknown,
          critical:
            score !== null && score >= 0 && score < 2.5
              ? sum.protectionLevel.critical + 1
              : sum.protectionLevel.critical,
          low:
            score >= 2.5 && score < 5
              ? sum.protectionLevel.low + 1
              : sum.protectionLevel.low,
          medium:
            score >= 5 && score < 7.5
              ? sum.protectionLevel.medium + 1
              : sum.protectionLevel.medium,
          high:
            score >= 7.5 && score <= 10
              ? sum.protectionLevel.high + 1
              : sum.protectionLevel.high,
        },
      };
    },
    {
      nbLicenses: {
        total: 0,
        sent: 0,
        used: 0,
      },
      purchasedLicenses: {
        lessThan10: 0,
        between10And50: 0,
        moreThan50: 0,
      },
      subscriptionStatus: {
        expired: 0,
        lessThan3Months: 0,
        moreThan3Months: 0,
      },
      avgScore: {
        avg: 0,
        n: 0,
      },
      protectionLevel: {
        unknown: 0,
        critical: 0,
        low: 0,
        medium: 0,
        high: 0,
      },
    }
  );

const PartnerDashboard = () => {
  const { partnerClients, loading } = usePartnerClients();
  const i18n = useTranslation();
  const navigate = useNavigate();

  const [filter, setFilter] = useState(null);
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: 'score',
    order: 'descend',
  });

  const partnerClientsFiltered = filter?.operator
    ? partnerClients.filter(OPERATOR_FILTER[filter.operator].callback)
    : partnerClients;

  const allClientsData = getClientsData(partnerClients);
  const filteredClientsData = getClientsData(partnerClientsFiltered);

  const clientsByProtectionLevel =
    filter?.field === WIDGET.PROTECTION_LEVEL
      ? allClientsData
      : filteredClientsData;

  const clientsByLicenses =
    filter?.field === WIDGET.LICENSES ? allClientsData : filteredClientsData;

  const clientsBySubscriptionStatus =
    filter?.field === WIDGET.SUBSCRIPTION_STATE
      ? allClientsData
      : filteredClientsData;

  const clientsByLicensesRange =
    filter?.field === WIDGET.LICENSES_RANGE
      ? allClientsData
      : filteredClientsData;

  const sentLicenses = clientsByLicenses.nbLicenses.sent;
  const usedLicenses = clientsByLicenses.nbLicenses.used;
  const totalLicenses = clientsByLicenses.nbLicenses.total;
  const sentNotInstalledLicenses = sentLicenses - usedLicenses;
  const availableLicensesToSend = totalLicenses - sentLicenses;

  const chartValuesFormatter = (value) => {
    return value % 1 !== 0 ? value.toFixed(1) : value.toFixed(0);
  };

  const updateFilter = (newFilter) => {
    setFilter((prevValue) =>
      prevValue?.operator === newFilter.operator ? null : newFilter
    );
  };

  const updateSortedInfo = (columnKey) => {
    setSortedInfo({
      columnKey,
      order: 'descend',
    });
  };

  const CHART_OPTIONS_PROTECTION_LEVEL = {
    series:
      partnerClientsFiltered.length > 0
        ? [
            clientsByProtectionLevel.protectionLevel.unknown,
            clientsByProtectionLevel.protectionLevel.critical,
            clientsByProtectionLevel.protectionLevel.low,
            clientsByProtectionLevel.protectionLevel.medium,
            clientsByProtectionLevel.protectionLevel.high,
          ]
        : [1],
    options: getDonutChartOptions({
      labels:
        partnerClientsFiltered.length > 0
          ? [
              i18n.t('partner.dashboard.protectionLevelChart.labels.unknown'),
              i18n.t('partner.dashboard.protectionLevelChart.labels.critical'),
              i18n.t('partner.dashboard.protectionLevelChart.labels.low'),
              i18n.t('partner.dashboard.protectionLevelChart.labels.medium'),
              i18n.t('partner.dashboard.protectionLevelChart.labels.high'),
            ]
          : [i18n.t('partner.dashboard.protectionLevelChart.labels.unknown')],
      colors:
        partnerClientsFiltered.length > 0
          ? [
              'var(--light-grey-3)',
              'var(--red)',
              'var(--light-red)',
              'var(--orange)',
              'var(--green)',
            ]
          : ['var(--light-grey-3)'],
      dataLabelsColors: [
        'var(--bluish-grey)',
        'var(--white)',
        'var(--bluish-grey)',
        'var(--white)',
        'var(--white)',
      ],
      dataLabelsEnable: partnerClientsFiltered.length > 0,
      legendWidth: 200,
      responsive: donutChartResponsiveOptions,
      dataPointSelectionEvent: (event, chartContext, config) => {
        const OPERATORS = {
          0: OPERATOR_FILTER.PROTECTION_LEVEL_UNKNOWN.key,
          1: OPERATOR_FILTER.PROTECTION_LEVEL_CRITICAL.key,
          2: OPERATOR_FILTER.PROTECTION_LEVEL_LOW.key,
          3: OPERATOR_FILTER.PROTECTION_LEVEL_MEDIUM.key,
          4: OPERATOR_FILTER.PROTECTION_LEVEL_HIGH.key,
        };

        const auxFilter = {
          field: WIDGET.PROTECTION_LEVEL,
          operator: OPERATORS[config.dataPointIndex],
        };

        updateFilter(auxFilter);
        updateSortedInfo('score');
      },
    }),
  };

  const CHART_OPTIONS_LICENSES = {
    series: totalLicenses
      ? [usedLicenses, sentNotInstalledLicenses, availableLicensesToSend]
      : [1],
    options: getDonutChartOptions({
      labels: totalLicenses
        ? [
            i18n.t('controlPanel.widgetSubscription.licensesInstalled'),
            i18n.t('controlPanel.widgetSubscription.licensesSent'),
            i18n.t('controlPanel.widgetSubscription.lisensesAvailable'),
          ]
        : [i18n.t('controlPanel.widgetSubscription.lisensesAvailable')],
      colors: totalLicenses
        ? ['var(--green)', 'var(--light-green2)', 'var(--light-grey-3)']
        : ['var(--light-grey-3)'],
      dataLabelsColors: totalLicenses
        ? ['var(--light-grey-4)', 'var(--light-grey-4)', 'var(--bluish-grey)']
        : ['var(--bluish-grey)'],
      dataLabelsEnable: !!totalLicenses,
      legendWidth: 200,
      responsive: donutChartResponsiveOptions,
      dataPointSelectionEvent: (event, chartContext, config) => {
        const OPERATORS = {
          0: OPERATOR_FILTER.LICENSES_INSTALLED.key,
          1: OPERATOR_FILTER.LICENSES_SENT_NOT_INSTALLED.key,
          2: OPERATOR_FILTER.LICENSES_AVAILABLE_TO_SEND.key,
        };

        const auxFilter = {
          field: WIDGET.LICENSES,
          operator: OPERATORS[config.dataPointIndex],
        };

        updateFilter(auxFilter);
        updateSortedInfo('licenses');
      },
    }),
  };

  const CHART_OPTIONS_SUBSCRIPTION_STATE = {
    series: [
      {
        data: [
          clientsBySubscriptionStatus.subscriptionStatus.expired,
          clientsBySubscriptionStatus.subscriptionStatus.lessThan3Months,
          clientsBySubscriptionStatus.subscriptionStatus.moreThan3Months,
        ],
      },
    ],
    options: getBarChartOptions({
      categories: [
        i18n.t(
          'partner.dashboard.subscriptionExpirationChart.categories.expired'
        ),
        i18n.t(
          'partner.dashboard.subscriptionExpirationChart.categories.lessThan3Months'
        ),
        i18n.t(
          'partner.dashboard.subscriptionExpirationChart.categories.moreThan3Months'
        ),
      ],
      colors: ['var(--partner-primary)'],
      tooltipFormatter: () => `${i18n.t('common.clients')}:`,
      valuesFormatter: (value) => chartValuesFormatter(value),
      dataPointSelectionEvent: (event, chartContext, config) => {
        const OPERATORS = {
          0: OPERATOR_FILTER.SUBSCRIPTION_STATE_EXPIRED.key,
          1: OPERATOR_FILTER.SUBSCRIPTION_STATE_LESS_THAN_3_MONTHS.key,
          2: OPERATOR_FILTER.SUBSCRIPTION_STATE_MORE_THAN_3_MONTHS.key,
        };

        const auxFilter = {
          field: WIDGET.SUBSCRIPTION_STATE,
          operator: OPERATORS[config.dataPointIndex],
        };

        updateFilter(auxFilter);
        updateSortedInfo('expiredAt');
      },
    }),
  };

  const CHART_OPTIONS_LICENSES_RANGE = {
    series: [
      {
        data: [
          clientsByLicensesRange.purchasedLicenses.moreThan50,
          clientsByLicensesRange.purchasedLicenses.between10And50,
          clientsByLicensesRange.purchasedLicenses.lessThan10,
        ],
      },
    ],
    options: getBarChartOptions({
      categories: [
        i18n.t(
          'partner.dashboard.purchasedLicensesRangeChart.categories.moreThan50'
        ),
        i18n.t(
          'partner.dashboard.purchasedLicensesRangeChart.categories.between10And50'
        ),
        i18n.t(
          'partner.dashboard.purchasedLicensesRangeChart.categories.lessThan10'
        ),
      ],
      colors: ['var(--partner-primary)'],
      tooltipFormatter: () => `${i18n.t('common.clients')}:`,
      valuesFormatter: (value) => chartValuesFormatter(value),
      dataPointSelectionEvent: (event, chartContext, config) => {
        const OPERATORS = {
          0: OPERATOR_FILTER.LICENSES_RANGE_MORE_THAN_50.key,
          1: OPERATOR_FILTER.LICENSES_RANGE_BETWEEN_10_AND_50.key,
          2: OPERATOR_FILTER.LICENSES_RANGE_LESS_THAN_10.key,
        };

        const auxFilter = {
          field: WIDGET.LICENSES_RANGE,
          operator: OPERATORS[config.dataPointIndex],
        };

        updateFilter(auxFilter);
        updateSortedInfo('licenses');
      },
    }),
  };

  const getSortOrder = (columnKey) => {
    return sortedInfo.columnKey === columnKey ? sortedInfo.order : null;
  };

  const getFilteredClientsColumns = () => {
    const filterField = filter?.field;
    const sortDirections = ['ascend', 'descend', 'ascend'];
    const renderCallback = (text) => (
      <span style={{ color: 'var(--bluish-grey)' }}>{text}</span>
    );

    const filteredClientsColumns = [
      {
        title: i18n.t('partner.dashboard.clientsTable.client'),
        dataIndex: 'name',
        key: 'name',
        sortDirections,
        sorter: ({ nameToSort: aNameToSort }, { nameToSort: bNameToSort }) => {
          if (!aNameToSort) return 1;
          if (!bNameToSort) return -1;

          return aNameToSort.localeCompare(bNameToSort);
        },
        sortOrder: getSortOrder('name'),
      },
    ];

    if (!filterField || filterField === WIDGET.PROTECTION_LEVEL) {
      filteredClientsColumns.push({
        title: i18n.t('partner.dashboard.clientsTable.score'),
        dataIndex: 'score',
        key: 'score',
        render: renderCallback,
        sortDirections,
        sorter: (a, b) => a.scoreToSort - b.scoreToSort,
        sortOrder: getSortOrder('score'),
      });
    }

    if (
      filterField === WIDGET.LICENSES_RANGE ||
      filterField === WIDGET.LICENSES
    ) {
      filteredClientsColumns.push({
        title: i18n.t('partner.dashboard.clientsTable.licenses'),
        dataIndex: 'licenses',
        key: 'licenses',
        render: renderCallback,
        sortDirections,
        sorter: (a, b) => a.licenses - b.licenses,
        sortOrder: getSortOrder('licenses'),
      });
    }

    if (filterField === WIDGET.SUBSCRIPTION_STATE) {
      filteredClientsColumns.push({
        title: i18n.t('partner.dashboard.clientsTable.expiration'),
        dataIndex: 'expiredAt',
        key: 'expiredAt',
        render: renderCallback,
        sortDirections,
        sorter: (a, b) => a.expiredAtToSort - b.expiredAtToSort,
        sortOrder: getSortOrder('expiredAt'),
      });
    }

    return filteredClientsColumns;
  };

  const filteredClientsTableData = partnerClientsFiltered.map(
    ({ id, company, scoring, status }) => {
      const subscriptionStatus = getSubscriptionStatus(status);
      const isSubscriptionExpired =
        subscriptionStatus === PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired;
      const renewalDate = new Date(status.nextRenewalAt);

      return {
        id,
        name: company.name || '-',
        nameToSort: company.name,
        score: Math.round(scoring.lastValue * 10) / 10 || '-',
        scoreToSort: scoring.lastValue || 0,
        licenses: status.nbLicenses,
        expiredAt: !isSubscriptionExpired ? getDateFormat(renewalDate) : '-',
        expiredAtToSort: !isSubscriptionExpired ? renewalDate : 0,
      };
    }
  );

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleResetFilter = () => {
    setFilter(null);
  };

  const handleCardHeaderFilter = (field) => () => {
    const sortedFields = {
      [WIDGET.LICENSES_RANGE]: 'licenses',
      [WIDGET.LICENSES]: 'licenses',
      [WIDGET.PROTECTION_LEVEL]: 'score',
      [WIDGET.SUBSCRIPTION_STATE]: 'expiredAt',
    };

    // Evitamos que estando filtrando por el gráfico, al hacer click en el header de la card, se elimine el filtro
    if (filter?.field !== field) {
      updateSortedInfo(sortedFields[field]);

      setFilter({
        field,
      });
    }
  };

  return (
    <SCPartnerDashboard>
      {!loading && (
        <div className="filter-container">
          {filter?.operator ? (
            <>
              <FontAwesomeIcon
                icon={faBarsFilter}
                fontSize="16px"
                color="var(--bluish-grey)"
              />
              <span className="filtering-by">
                {i18n.t('partner.dashboard.filter.filteringBy')}
              </span>
              <span className="filter-operator-tag">
                {i18n.t(`partner.dashboard.filter.values.${filter?.operator}`)}
                <button
                  className="close-button"
                  type="button"
                  onClick={handleResetFilter}>
                  <div className="icon-wrapper">
                    <FontAwesomeIcon icon={faXmark} size="xs" />
                  </div>
                </button>
              </span>
            </>
          ) : (
            <div className="filter-info">
              <FontAwesomeIcon
                icon={faCircleInfo}
                fontSize="16px"
                color="var(--bluish-grey)"
              />
              <p>{i18n.t('partner.dashboard.filter.emptyMessage')}</p>
            </div>
          )}
        </div>
      )}

      <div className="widgets-container">
        <KPICard
          value={partnerClientsFiltered.length}
          title={i18n.t('common.clients')}
          icon={faUserGroup}
          loading={loading}
        />

        <KPICard
          value={filteredClientsData.nbLicenses.total}
          title={i18n.t('partner.dashboard.purchasedLicenses')}
          icon={faShieldHalved}
          loading={loading}
        />

        <KPICard
          value={Math.round(filteredClientsData.avgScore.avg * 10) / 10}
          title={i18n.t('partner.dashboard.averageScore')}
          icon={faGaugeSimple}
          loading={loading}
        />
      </div>

      <div className="charts-table-container">
        <SectionCard
          headerTitle={i18n.t('partner.dashboard.protectionLevelChart.title')}
          headerIcon={faLaptopMobile}
          headerButtonOnClick={handleCardHeaderFilter(WIDGET.PROTECTION_LEVEL)}
          isHeaderSelected={filter?.field === WIDGET.PROTECTION_LEVEL}>
          {!loading ? (
            <ReactApexChart
              options={CHART_OPTIONS_PROTECTION_LEVEL.options}
              series={CHART_OPTIONS_PROTECTION_LEVEL.series}
              type="donut"
              height={175}
            />
          ) : (
            <div className="donut-chart-skeleton">
              <ChartSkeleton />
            </div>
          )}
        </SectionCard>

        <SectionCard
          headerTitle={i18n.t('partner.dashboard.licensesChart.title')}
          headerIcon={faLaptopMobile}
          headerButtonOnClick={handleCardHeaderFilter(WIDGET.LICENSES)}
          isHeaderSelected={filter?.field === WIDGET.LICENSES}>
          {!loading ? (
            <ReactApexChart
              options={CHART_OPTIONS_LICENSES.options}
              series={CHART_OPTIONS_LICENSES.series}
              type="donut"
              height={175}
            />
          ) : (
            <div className="donut-chart-skeleton">
              <ChartSkeleton />
            </div>
          )}
        </SectionCard>

        <SectionCard
          headerTitle={i18n.t(
            'partner.dashboard.subscriptionExpirationChart.title'
          )}
          headerIcon={faBadgeCheck}
          headerButtonOnClick={handleCardHeaderFilter(
            WIDGET.SUBSCRIPTION_STATE
          )}
          isHeaderSelected={filter?.field === WIDGET.SUBSCRIPTION_STATE}>
          {!loading ? (
            <ReactApexChart
              options={CHART_OPTIONS_SUBSCRIPTION_STATE.options}
              series={CHART_OPTIONS_SUBSCRIPTION_STATE.series}
              type="bar"
              width="100%"
              height="200px"
            />
          ) : (
            <div className="bar-chart-skeleton">
              <ChartSkeleton />
            </div>
          )}
        </SectionCard>

        <SectionCard
          headerTitle={i18n.t(
            'partner.dashboard.purchasedLicensesRangeChart.title'
          )}
          headerIcon={faLaptopMobile}
          headerButtonOnClick={handleCardHeaderFilter(WIDGET.LICENSES_RANGE)}
          isHeaderSelected={filter?.field === WIDGET.LICENSES_RANGE}>
          {!loading ? (
            <ReactApexChart
              options={CHART_OPTIONS_LICENSES_RANGE.options}
              series={CHART_OPTIONS_LICENSES_RANGE.series}
              type="bar"
              width="100%"
              height="200px"
            />
          ) : (
            <div className="bar-chart-skeleton">
              <ChartSkeleton />
            </div>
          )}
        </SectionCard>

        <SectionCard className="clients-table" hasPadding={false}>
          <Table
            columns={getFilteredClientsColumns()}
            dataSource={filteredClientsTableData}
            pagination={false}
            scroll={{ y: 560 }}
            rowKey={(record) => record.id}
            onRow={(record) => {
              return {
                onClick: () =>
                  navigate(`/partner/clients/control-panel/${record.id}`),
              };
            }}
            rowClassName={() => 'cursor-pointer'}
            onChange={handleChange}
            locale={{
              ...(loading && {
                emptyText: <Skeleton active />,
              }),
            }}
          />
        </SectionCard>
      </div>
    </SCPartnerDashboard>
  );
};

export default PartnerDashboard;
