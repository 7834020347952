import React from 'react';
import { faBadgeCheck } from '@fortawesome/pro-regular-svg-icons';
import ReactApexChart from 'react-apexcharts';
import { Link, useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { SUBSCRIPTIONS_PRODUCTS } from '../../../hooks/subscriptionType/useSubscriptionType';
import { getDonutChartOptions } from '../../../utils/chartFactory/chartFactory';
import LabelDetail from '../../molecules/LabelDetail/LabelDetail';
import { getDateFormat } from '../../../utils/format/dataFormat';
import SCMySubscriptionCard from './MySubscriptionCard.style';
import { isPartnerType } from '../../../utils/functions/partners';
import { PARTNER_TYPES } from '../../../utils/constants/partners';

const MySubscriptionCard = ({
  totalLicenses,
  installedLicenses,
  sentLicenses,
  currentSubscriptionProduct,
  currentSubscriptionExpirationDate,
  nextRenewalAt,
  infoBanner,
  showLinkToPlans,
  isHeaderClickable,
  loading = false,
  className,
  isTwoColumnLayout = true,
}) => {
  const i18n = useTranslation();
  const navigate = useNavigate();

  const sentNotInstalledLicenses = sentLicenses - installedLicenses;
  const availableLicensesToSend = totalLicenses - sentLicenses;
  const subscriptionExpired = currentSubscriptionExpirationDate < new Date();

  const getSubscriptionStatus = () => {
    if (!subscriptionExpired) {
      switch (currentSubscriptionProduct) {
        case SUBSCRIPTIONS_PRODUCTS.DEFAULT:
          return i18n.t('controlPanel.widgetSubscription.inactive');
        case SUBSCRIPTIONS_PRODUCTS.TRIAL:
          return i18n.t('controlPanel.widgetSubscription.trialActive');
        case SUBSCRIPTIONS_PRODUCTS.PAYING:
          return i18n.t('controlPanel.widgetSubscription.active');
        default:
          break;
      }
    }
    return i18n.t('controlPanel.widgetSubscription.inactive');
  };

  const CHART_OPTIONS_YOUR_PROTECTION = {
    series: totalLicenses
      ? [installedLicenses, sentNotInstalledLicenses, availableLicensesToSend]
      : [1],
    options: getDonutChartOptions({
      labels: totalLicenses
        ? [
            i18n.t('controlPanel.widgetSubscription.licensesInstalled'),
            i18n.t('controlPanel.widgetSubscription.licensesSent'),
            i18n.t('controlPanel.widgetSubscription.lisensesAvailable'),
          ]
        : [i18n.t('controlPanel.widgetSubscription.lisensesAvailable')],
      colors: totalLicenses
        ? ['var(--green)', 'var(--light-green2)', 'var(--light-grey-3)']
        : ['var(--light-grey-3)'],
      dataLabelsColors: totalLicenses
        ? ['var(--light-grey-4)', 'var(--light-grey-4)', 'var(--bluish-grey)']
        : ['var(--bluish-grey)'],
      dataLabelsEnable: !!totalLicenses,
    }),
  };

  const endDate = getDateFormat(currentSubscriptionExpirationDate);
  const isPartnerMSSP = isPartnerType(PARTNER_TYPES.MSSP);

  return (
    <SCMySubscriptionCard
      className={className}
      isTwoColumnLayout={isTwoColumnLayout}>
      <SectionCard
        headerIcon={faBadgeCheck}
        headerTitle={i18n.t('controlPanel.widgetSubscription.title')}
        headerButtonOnClick={
          isHeaderClickable
            ? () => navigate('/client/settings/billing')
            : undefined
        }>
        {!loading ? (
          <div className="subscription-card-container">
            <div className="subscription-card">
              <div className="your-subscription-text-container">
                <LabelDetail
                  name={i18n.t('controlPanel.widgetSubscription.status')}
                  value={getSubscriptionStatus()}
                />
                <LabelDetail
                  name={i18n.t('controlPanel.widgetSubscription.plan')}
                  value={totalLicenses}
                />
                {currentSubscriptionProduct ===
                  SUBSCRIPTIONS_PRODUCTS.TRIAL && (
                  <LabelDetail
                    name={i18n.t('controlPanel.widgetSubscription.trialPeriod')}
                    value={endDate}
                  />
                )}
                {currentSubscriptionProduct ===
                  SUBSCRIPTIONS_PRODUCTS.PAYING && (
                  <>
                    <LabelDetail
                      name={i18n.t(
                        'controlPanel.widgetSubscription.subscriptionPeriod'
                      )}
                      value={getDateFormat(nextRenewalAt)}
                    />
                    {!isPartnerMSSP && (
                      <LabelDetail
                        name={i18n.t(
                          'controlPanel.widgetSubscription.nextInvoice'
                        )}
                        value={endDate}
                      />
                    )}
                  </>
                )}
              </div>

              <ReactApexChart
                options={CHART_OPTIONS_YOUR_PROTECTION.options}
                series={CHART_OPTIONS_YOUR_PROTECTION.series}
                type="donut"
                width="450"
              />
            </div>

            {infoBanner}

            {showLinkToPlans && (
              <Link to="/license-plan-features">
                {i18n.t('licensePlanFeatures.linkText')}
              </Link>
            )}
          </div>
        ) : (
          <Skeleton active paragraph={{ rows: 5 }} />
        )}
      </SectionCard>
    </SCMySubscriptionCard>
  );
};
export default MySubscriptionCard;
